@import "tailwindcss/base";
@import "variables.scss";
@import "tailwindcss/utilities";

@import "variables";
@import "fonts";
@import "typography";

html body {
  margin: 0 auto;
  font-family: $pluto;
  color: $primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  -webkit-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
*:-moz-focusring,
*:focus {
  outline: none !important;
}

header,
body {
  @apply dark:bg-dark-bg dark:text-dark-text;
}

.dpd-border {
  &-full {
    border: 1px solid $iron;
  }

  &-left {
    border-left: 1px solid $iron;
  }

  &-right {
    border-right: 1px solid $iron;
  }

  &-bottom {
    border-bottom: 1px solid $iron;
  }

  &-table {
    border: 1px solid $iron;
    border-collapse: collapse;

    @media (max-width: 640px) {
      th,
      td {
        padding: 4px; // reduce padding on small screens
      }
      overflow-x: auto; // add horizontal scrollbar when content overflows
    }
  }

  &-top {
    border-top: 1px solid $iron;
  }
}

.dpd-button-border {
  border: 2px solid #e5444b;

  &-green {
    @extend .dpd-button-border;
    border-color: #66b553;
  }

  &-black {
    @extend .dpd-button-border;
    border-color: black;
  }
}

@layer utilities {
  .dpd-red {
    color: $brand_red;
  }
}

.select-arrow {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDE0IDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xMS45NzI1IDAuMzA1Nzg2TDcgNS4yNjc0NUwyLjAyNzUgMC4zMDU3ODZMMC41IDEuODMzMjlMNyA4LjMzMzI5TDEzLjUgMS44MzMyOUwxMS45NzI1IDAuMzA1Nzg2WiIgZmlsbD0iIzgwODA4MCIvPgo8L3N2Zz4K");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
}

.markdown-table {
  table {
    margin: 10px 0px;
    @media (max-width: 640px) {
      margin: 5px 0px;
    }
  }
  th,
  td {
    padding: 8px 16px;
    border: 1px solid $iron;
    border-collapse: collapse;
    text-align: center;

    @media (max-width: 640px) {
      padding: 1px 2px;
    }
  }
}
