@font-face {
  font-family: "PlutoSans";
  font-weight: 100;
  src: local("PlutoSansLight"), url("./fonts/PlutoSansLight.ttf");
}

@font-face {
  font-family: "PlutoSans";
  font-weight: 100;
  font-stretch: condensed;
  src: local("PlutoSansExtraLight"), url("./fonts/PlutoSansExtraLight.ttf");
}

@font-face {
  font-family: "PlutoSans";
  font-stretch: condensed;
  src: local("PlutoSansThin"), url("./fonts/PlutoSansThin.ttf");
}

@font-face {
  font-family: "PlutoSans";
  font-weight: bold;
  src: local("PlutoSansBold"), url("./fonts/PlutoSansBold.ttf");
}

@font-face {
  font-family: "PlutoSans";
  font-weight: normal;
  src: local("PlutoSansRegular"), url("./fonts/PlutoSansRegular.ttf");
}

@font-face {
  font-family: "Source Code Pro";
  font-weight: normal;
  src: local("SourceCodePro-Regular"), url("./fonts/SourceCodePro-Regular.ttf");
}
