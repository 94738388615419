@import "variables";
@import "fonts";

.dpd-heading {
  font-size: 23px;
  font-weight: 370;
  line-height: 30px;
  letter-spacing: 0.3px;
}

.dpd-sub-heading {
  font-size: 18px;
  font-weight: 370;
  line-height: 22px;
  letter-spacing: 0.3px;
}

.dpd-nav-text {
  font-size: 14px;
  font-weight: 370;
  line-height: 20px;
  letter-spacing: 0.3px;
}

.dpd-text {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0.3px;
  font-family: $pluto;

  &-button {
    @extend .dpd-text;
    font-size: 14px;
    line-height: 24px;
  }

  &-small {
    @extend .dpd-text;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0em;
  }

  &-light {
    @extend .dpd-text;
    font-weight: 370;
  }

  &-code {
    @extend .dpd-text;
    font-family: $source_code;

    &-block {
      @extend .dpd-text;
      color: $purple;
      background-color: $code_block;
      font-family: $source_code;
    }
  }
}
