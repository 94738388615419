$pluto: PlutoSans, sans-serif;
$source_code:
  Source Code Pro,
  sans-serif;
$iron: #e6e7e8;
$rolling_stone: #808285;
$primary: #414042;
$purple: #6f238a;
$code_block: rgba(0, 0, 0, 0.05);
$brand_red: #dc0032;
$green: #66b553;
$blue: #e5f0ff;
$code_bg: #0000000d;

:export {
  pluto: $pluto;
  source_code: $source_code;
  primary: $primary;
  purple: $purple;
  code_block: $code_block;
  iron: $iron;
  brand_red: $brand_red;
  green: $green;
  blue: $blue;
  code_bg: $code_bg;
}
